<template>
  <header>
    <meta
      name="viewport"
      content="width=device-width,initial-scale=1.0, maximum-scale=1.0,user-scalable=no"
    />
    <!-- <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" > -->
  </header>
  <main>
    <div class="logo-wrap clearfix">
      <h1>
        <a href="" title="重庆广大融媒科技有限公司">重庆广大融媒科技有限公司</a>
      </h1>
    </div>
    <div id="pop">
      <div class="content">
        <p>IOS端APP还未完成，可以访问 Android 端哦！</p>
        <a href=" ">前往Android端</a>
      </div>
    </div>
    <div class="down_box">
      <h4 class="main-title">两江上游云</h4>
      <div class="down-btn">
        <a>下载 Android版</a>
      </div>
    </div>
    <div class="content-wrap">
      <!-- android -->
      <div id="android" style="display: none; margin: 10px 0 0">
        <img
          src="@/assets/image/download/dec-android.png"
          alt="下载 Android版"
        />
      </div>
      <!-- ios -->
      <div id="ios" style="display: none; margin: 10px 0 0">
        <img src="@/assets/image/download/dec-android.png" alt="下载 ios版" />
        <!-- <h4 class="title">安装完成后，请到：</h4>
                    <div class="item">
                        【设置】 - 【通用】 - 【设备管理器】 <br/>找到滚石矿工 APP的描述文件，设置为信任
                    </div>
                    <div class="item">
                        <p>1 .到手机“设置”中，点击“通用”；</p>
                        <img src="@/assets/image/download/dec-ios-001.jpg" alt="1 .到手机“设置”中，点击“通用”；">
                    </div>
                    <div class="item">
                        <p>2.点击“设备管理”</p>
                        <img src="@/assets/image/download/dec-ios-002.jpg" alt="2.点击“设备管理”">
                    </div>
                    <div class="item">
                        <p>3.点击要选择的设备</p>
                        <img src="@/assets/image/download/dec-ios-003.jpg" alt="3.点击要选择的设备">
                    </div>
                    <div class="item">
                        <p>4.点击信任</p>
                        <img src="@/assets/image/download/dec-ios-004.jpg" alt="4.点击信任">
                    </div>
                    <div class="item">
                        <p>5.在弹出窗口中选中“信任”</p>
                        <img src="@/assets/image/download/dec-ios-005.jpg" alt="5.在弹出窗口中选中“信任”">
                    </div>
                    <div class="item">
                        <p>6.安装成功，请在桌面查看；</p>
                    </div> -->
      </div>
    </div>
  </main>
  <footer>
    <div class="footer_box">
      <div class="qr-code clearfix">
        <div class="img">
          <img src="@/assets/picture/download/qr-img.jpg" alt="" />
        </div>
        <div class="info">
          <p>微信公众号</p>
        </div>
      </div>
      <div id="copyright">
        重庆广大融媒科技有限公司 ©2021
        <a href="https://beian.miit.gov.cn" target="_blank"
          >备案号：渝ICP备2021004499号</a
        >
      </div>
      <br />
    </div>
  </footer>
</template>

<script>
import { onBeforeMount, onMounted } from "vue";

import "@/assets/css/download.normalize.css";
import "@/assets/css/download.main.phone.css";

export default {
  name: "DownloadPhone",
  setup() {
    onBeforeMount(() => {
      let viewSize = document.documentElement.clientWidth;
      if (viewSize > 767) {
        window.location.href = "./downloadApp";
      }
    });
    onMounted(() => {
      const domIos = document.getElementById("ios");
      const domAndroid = document.getElementById("android");
      const downBtn = document.querySelector(".down-btn a");
      const maskPop = document.querySelector(".mask-pop");

      init();
      downBtn.addEventListener("touchstart", function () {
        if (isWeiXin()) {
          maskPop.style.display = "block";
          return false;
        }
        let system = getSystem();
        // let href = '';
        // let href = (system === 'android')
        // ? 'https://download.rockminer.io/Android/RIO.apk'  // android
        // : 'itms-services://?action=download-manifest&url=https://download.rockminer.io/IOS/miner.plist';
        if (system === "android") {
          window.location.href = "https://www.cqgdrm.com/download/ljsyy.apk";
        } else {
          const dom = document.getElementById("pop");
          dom.style.display = "flex";
        }
        // let href = (system === 'android')
        // ? 'http://file.web-jshtml.cn/webjshtml.apk'  // android
        // : 'https://lifetech.me/bvyPZ9';
        // window.location.href = href
      });
      /**
       * 初始化
       */
      function init() {
        if (getSystem() === "ios") {
          domIos.style.display = "block";
          downBtn.innerHTML = "下载 IOS版";
        }
        if (getSystem() === "android") {
          domAndroid.style.display = "block";
          downBtn.innerHTML = "下载 Android版";
        }
      }
      /**
       * 判断 IOS\ANDROID
       */
      function getSystem() {
        let u = navigator.userAgent;
        let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //g
        let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        return isAndroid ? "android" : "ios";
      }
      /**
       * 判断是否是微信浏览器
       */
      function isWeiXin() {
        //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
        let ua = window.navigator.userAgent.toLowerCase();
        //通过正则表达式匹配ua中是否含有MicroMessenger字符串
        return ua.match(/MicroMessenger/i) == "micromessenger" ? true : false;
      }
    });
  },
};
</script>

<style scoped>
.down_box {
  position: absolute;
  top: 4%;
  left: 30%;
}
</style>